import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Link, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"works"} />
		<Helmet>
			<title>
				Портфолио | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:title"} content={"Портфолио | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
			align-items="center"
			justify-content="center"
		>
			<Components.Header />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				xl-grid-template-columns="1fr"
				max-width="2560px"
				width="100%"
			>
				<Text
					margin="0px 0px 26px 0px"
					color="--darkL2"
					font="--headline2"
					width="100%"
					sm-font="--headline3"
					grid-column="1/3"
					xl-grid-column="1/2"
					sm-margin="0px 0px 12px 24px"
				>
					Works
				</Text>
				<Components.CaseCard
					margin="0px 0 0px 0"
					padding="0px 0px 0px 0px"
					border-color="none"
					border-radius="10px"
					border-width={0}
					background="--color-quarklyBg"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Override slot="box2" />
					<Override
						slot="link1"
						href="https://quarkly.io/"
						text-decoration-line="initial"
						target="_blank"
						color="--quarkly"
						border-color="--color-quarkly"
					>
						quarkly.io
					</Override>
					<Override slot="text" color="--quarkly">
						Quarkly
					</Override>
					<Override slot="SectionContent" xl-max-width="100%" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						No-code / Low-code platform for creating websites and web apps
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							.
						</Strong>
						{" "}Harness the power of web design and create ready-to-use websites and apps on React
					</Override>
					<Override slot="button" background="#6CFFE4" color="--darkL2" display="none" />
					<Override slot="text2" color="--grey">
						UX / UI design, product design, management
					</Override>
					<Override slot="box" height="auto" flex="1 1 0%" />
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-index-image-en-1.png?v=2022-07-18T13:40:48.810Z" />
				</Components.CaseCard>
				<Components.CaseCard border-radius="10px" background="#f3e5f5" overflow-x="hidden" overflow-y="hidden">
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text" color="--webask">
						WebAsk
					</Override>
					<Override slot="text1" color="--darkL2">
						Online questionnaire and survey builder. With it you can create surveys, questionnaires, collect feedback, create registration forms or even vote
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI design, product design, management
					</Override>
					<Override slot="text3" />
					<Override slot="link1" color="--webask" border-color="--color-webask" />
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-webask-index-image-en-1.png?v=2022-07-18T16:19:56.290Z" />
					<Override slot="button" background="--color-webask" href="/portfolio/webask">
						View case →
					</Override>
					<Override slot="box2" />
					<Override slot="box1" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					padding="0px 0px 0px 0px"
					margin="0px 0 0px 0"
					border-radius="10px"
					background="#b9f6ca"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Override slot="text" color="--stepform" sm-font="--headline3">
						StepFORM
					</Override>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						Builder where you can create online multi step forms with scoring and calculation for websites, messengers and social networks
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI design, product design, management
					</Override>
					<Override slot="text3" color="white" border-color="white">
						stepform.io
					</Override>
					<Override
						slot="link1"
						href="https://stepform.io/"
						target="_blank"
						text-decoration-line="initial"
						border-color="--color-stepform"
						color="--stepform"
						md-margin="0px 0px 26px 0px"
					>
						stepform.io
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-index-image-en-1.png?v=2022-07-18T13:55:04.533Z" />
					<Override
						slot="button"
						type="link"
						text-decoration-line="initial"
						href="/works/stepform"
						background="--color-stepform"
					>
						View case →
					</Override>
					<Override
						slot="box2"
						md-flex-direction="column"
						md-justify-content="flex-start"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
						margin="auto 0px 0px 0px"
					/>
					<Override slot="box1" margin="0px 0px 40px 0px" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					padding="0px 0px 0px 0px"
					margin="0px 0 0px 0"
					border-radius="10px"
					border-color="#FECB2F"
					background="#fff9c4"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Override slot="text" color="--ukitalt" sm-font="--headline3">
						uKit Alt
					</Override>
					<Override slot="SectionContent" background="none" flex-direction="column" align-items="flex-start" />
					<Override slot="text1" color="--darkL2">
						Addition to the constructor uKit, with which users of Facebook and VK can make a site from their group or profile
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI design, product design, management
					</Override>
					<Override slot="text3" color="white" border-color="white" />
					<Override
						slot="button"
						background="--color-ukitalt"
						color="--darkL2"
						display="block"
						type="link"
						text-decoration-line="initial"
						href="/works/ukitalt"
					>
						View case →
					</Override>
					<Override
						slot="link1"
						color="--ukitalt"
						text-decoration-line="initial"
						href="https://ukit.com/lp/convert"
						target="_blank"
						border-color="--color-ukitalt"
						md-margin="0px 0px 26px 0px"
					>
						ukit.com/lp/convert
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-website-type-en-1.png?v=2022-07-18T14:43:18.620Z" />
					<Override
						slot="box2"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
						md-flex-direction="column"
						md-justify-content="flex-start"
					/>
					<Override slot="box1" />
					<Override slot="box" height="auto" position="static" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					padding="0px 0px 0px 0px"
					margin="0px 0 0px 0"
					border-radius="10px"
					background="#e8eaf6"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Override slot="text" color="--ukit" sm-font="--headline3">
						uKit
					</Override>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						Simple website builder for business, where you can make sites without special skills
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI design
					</Override>
					<Override slot="text3" color="white" border-color="white" />
					<Override
						slot="button"
						background="--color-ukit"
						color="--light"
						display="block"
						type="link"
						text-decoration-line="initial"
						href="/works/ukit"
					>
						View case →
					</Override>
					<Override
						slot="link1"
						color="--ukit"
						text-decoration-line="initial"
						href="https://ukit.com/"
						target="_blank"
						border-color="--color-ukit"
						md-margin="0px 0px 26px 0px"
					>
						ukit.com
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-index-image-en-1.png?v=2022-07-18T14:17:10.635Z" />
					<Override
						slot="box2"
						md-flex-direction="column"
						md-justify-content="flex-start"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
					/>
					<Override slot="box1" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					margin="0px 0 0px 0"
					padding="0px 0px 0px 0px"
					border-radius="10px"
					background="#ffe0b2"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text" color="#f4511e" sm-font="--headline3">
						Small projects
					</Override>
					<Override slot="text1" color="--darkL2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Link
								href="http://nygame.ukit.space/"
								target="_blank"
								text-decoration-line="initial"
								color="#f4511e"
								border-width="0 0 1px 0"
								border-style="solid"
								border-color="#f4511e"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Discount Hunter
							</Link>
							{" "}
						</Strong>
						an entertaining game for uKit customers.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Link
								href="https://bigmaconomics.com/"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								color="#f4511e"
								text-decoration-line="initial"
								border-width="0 0 1px 0"
								border-style="solid"
								border-color="#f4511e"
								target="_blank"
							>
								BigMaconomics
							</Link>
						</Strong>
						{" "}a mock mini-application to calculate the number of BigMacs on your salary.
					</Override>
					<Override slot="text2" color="--grey">
						UI design, illustrations, management, concept
					</Override>
					<Override slot="text3" color="white" border-color="white">
						ukit.com
					</Override>
					<Override
						slot="button"
						background="#f4511e"
						color="white"
						margin="auto 0px 0px auto"
						font="normal 400 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						type="link"
						text-decoration-line="initial"
						href="/works/mini-projects"
						display="block"
					>
						View →
					</Override>
					<Override
						slot="link1"
						color="#FFF"
						text-decoration-line="initial"
						target="_blank"
						href="https://ukit.com/"
						border-color="#FFF"
						display="none"
					>
						ukit.com
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-mini-projects-index-image-en-1.png?v=2022-07-18T14:54:22.712Z" />
					<Override slot="box1" color="--light" />
					<Override slot="box2" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
			</Box>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});